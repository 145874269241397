import React from "react";
import { Link } from "react-router-dom";
import { FolderIcon, DocumentAddIcon } from '@heroicons/react/solid'
import { motion } from "framer-motion";

export const Fournisseur = () => {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div class="max-w-sm mx-auto lg:flex-col mt-6 flex justify-center">
        <Link
          to="/factures"
          className="w-full text-center drop-shadow-lg border-2 border-blue-500 text-base lg:text-2xl font-bold text-blue-500 mt-8 px-6 py-6 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white mr-6"
        >
          <FolderIcon className="w-full h-8 w-8" /> Retrouvez vos factures
        </Link>

        <Link
          to="/ajouter"
          className="w-full text-center drop-shadow-lg border-2 border-blue-500 text-base lg:text-2xl font-bold text-blue-500 mt-8 px-6 py-6 transition duration-300 ease-in-out hover:bg-blue-500 hover:text-white mr-6"
        >
          <DocumentAddIcon className="w-full h-8 w-8" /> Déposez une nouvelle facture
        </Link>
      </div>
    </motion.div>
  );
};
