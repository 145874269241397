import { React, useContext } from "react";
import { AuthProvider } from "./contexts/Auth/AuthState";
import { NavBar } from "./components/NavBar";
import { Routes } from "./routes/Routes";

const App = () => {
  return (
    <AuthProvider>
      <div className="App">
        <div className="h-screen pb-14 bg-right bg-cover">
          <NavBar />
          <Routes />
        </div>
      </div>
    </AuthProvider >
  );
};

export default App;
