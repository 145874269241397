import React, { useContext } from "react";
import { LockClosedIcon, LoginIcon, ArrowDownIcon } from "@heroicons/react/solid";
import { motion } from "framer-motion"

import { AuthContext } from "../contexts/Auth/AuthState";

export const LandingPage = (props) => {
    useContext(AuthContext).redirectToMainPage();

    return (
        <motion.div
        exit={{ opacity: 0}}
        initial={{ opacity: 0, y: -200 }}
        animate={{ opacity: 1, y: 0 }}
        >
            <div className="container pt-24 md:pt-32 px-6 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                <div className="flex flex-col w-full mx-auto justify-center xl:w-3/5 py-6 lg:items-start overflow-y-hidden">
                    <img
                        className="w-full mx-auto sm:mx-0 sm:w-3/5 lg:mr-0 slide-in-bottom "
                        alt="logo"
                        src="logo.png"
                    />
                </div>

                <div className="xl:w-2/5 overflow-y-hidden">
                    <h1 className="my-4 text-3xl md:text-5xl text-blue-700 font-bold leading-tight text-center md:text-left slide-in-bottom-h1">
                        e-Facture
                    </h1>
                    <p className="leading-normal text-base md:text-2xl mb-8 text-center md:text-left slide-in-bottom-subtitle">
                        e-facture est une plateforme de dépôt électronique des factures des fournisseurs de la SNRT.
                    </p>

                    <div className="flex w-full justify-center md:justify-start sm:pb-0 pb-4 fade-in">
                        <button
                            className="flex py-2 px-8 w-48 font-semibold rounded shadow text-white bg-green-600 hover:bg-green-700"
                            onClick={() => props.history.push("/login")}
                        >
                            <LockClosedIcon className="h-6 w-6 mx-1" /> Se connecter
                        </button>
                    </div>

                    <div className="flex w-full py-0 sm:py-4 justify-center md:justify-start fade-in">

                        <p className="flex text-blue-500 font-bold pb-8 lg:pb-6 text-center md:text-left fade-in">
                            Ou bien créez un compte ci-dessous  <ArrowDownIcon className="h-6 w-6 pt-2" />
                        </p>

                    </div>

                    <div className="flex w-full justify-center md:justify-start fade-in">

                        <button
                            className="flex py-2 px-10 w-48 font-semibold rounded shadow text-blue-500 border-2 border-blue-500
                            hover:bg-blue-600 hover:text-white hover:border-blue-700"
                            onClick={() => props.history.push("/register")}
                        >
                            <LoginIcon className="h-6 w-6 mx-1" /> S'inscrire
                        </button>

                    </div>
                </div>

                <div className="w-full pt-16 pb-6 text-sm text-center md:text-left fade-in">
                    <a className="text-gray-500 no-underline hover:no-underline" href="#">
                        &copy; SNRT 2021
                    </a>
                </div>
            </div>
        </motion.div>
    );
};
