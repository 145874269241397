import React, { useState, useEffect } from "react";
import { DocumentSearchIcon } from '@heroicons/react/solid'
import { motion } from 'framer-motion';

import apiClient from "../../services/api";
import { InvoiceRow } from "./InvoiceRow"
import { ReturnButton } from "../ReturnButton";

export const InvoiceList = () => {
    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState([]);
    const [search, setSearch] = useState(null);
    let [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [show, setShow] = useState("hidden");

    const fetchUserInvoices = () => {
        setLoading(true);
        console.log(page);
        const URL = "/api/invoices?page=" + page;
        apiClient.get(URL).then((response) => {
            const { next_page_url, data } = response.data;
            page === 1 ? setInvoices(data) : setInvoices([...invoices, ...data]);
            setHasMore(next_page_url === null ? false : true);
            setLoading(false);
        }, () => {
            console.log("Something went wrong.");
        }).catch(error => {
            console.log(error.message);
        });
    };

    useEffect(() => {
        fetchUserInvoices();
        return () => { }
    }, [page]);

    const openModal = () => {
        setIsOpen(true);
        setShow("");
    }

    return (
        <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
        >
            <body className="antialiased font-sans">
                <ReturnButton />
                <div className="container mx-auto px-4 sm:px-8">
                    <div className="py-4">
                        {/* <div>
                        <h2 className="flex text-2xl leading-tight">
                           <DocumentTextIcon className="h-8 w-8" /> Vos Factures
                        </h2>
                    </div> */}
                        <div className="my-2 w-full flex items-center justify-between">
                            <div className="my-2 w-full flex sm:flex-row flex-col">
                                <div className="w-full block relative">
                                    <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                                        <svg
                                            viewBox="0 0 24 24"
                                            className="h-4 w-4 fill-current text-gray-500"
                                        >
                                            <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                                        </svg>
                                    </span>
                                    <input
                                        onChange={e => setSearch(e.target.value)}
                                        placeholder="Rechercher par montant"
                                        className="shadow w-24 appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                                    />
                                </div>
                            </div>

                            {/* <div className="flex w-1/2 justify-end content-center">
                            <button
                                onClick={() => openModal()}
                                className="flex py-2 px-2 font-semibold rounded shadow-lg text-white bg-blue-500 hover:bg-blue-700">
                                <CloudUploadIcon className="h-6 w-6 mx-2" /> Télécharger Facture
                            </button>
                        </div> */}
                        </div>

                        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
                            <div className="inline-block min-w-full shadow-md rounded-md overflow-hidden">
                                <table className="min-w-full leading-normal">
                                    <thead>
                                        <tr>
                                            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                Fichier
                                            </th>
                                            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                Montant
                                            </th>
                                            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                                                Date de téléchargement
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoices && invoices.filter((invoice) => {
                                            if (search === null) return invoice; else if (invoice.number.toLowerCase().includes(search.toLowerCase())) return invoice;
                                        }).map((invoice, index) => (
                                            <InvoiceRow key={index} invoice={invoice} />
                                        ))}
                                    </tbody>
                                </table>
                                <div className="px-5 py-5 bg-white border-t flex flex-col xs:flex-row items-center xs:justify-between">
                                    <div className="inline-flex mt-2 xs:mt-0">
                                        {hasMore ?
                                            <button onClick={() => setPage(++page)} className="flex shadow-md float-left text-md text-sm bg-gray-100 hover:bg-gray-200 text-gray-800 font-semibold py-2 px-4 rounded-l">
                                                {loading ? <img class="" src="three-dots.svg" alt="loading" /> : <><DocumentSearchIcon className="h-4 w-4 mx-1" /> Afficher plus</>}
                                            </button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <AddInvoiceModal modalSettings={{ show, isOpen, setIsOpen }} /> */}
            </body>
        </motion.div>
    );
};
