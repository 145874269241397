import { UserIcon, LogoutIcon } from "@heroicons/react/solid";
import { React, useContext } from "react";
import { AuthContext } from "../contexts/Auth/AuthState";
import { InfoHeader } from "./Fournisseur/InfoHeader";

export const NavBar = () => {
  const { logout, user, checkAuth } = useContext(AuthContext);

  return (
    <>
      <div class="w-full container mx-auto p-8">
      {checkAuth() ? (
        <>
        <div class="w-full flex items-center justify-between">
              <a
                class="flex items-center text-blue-600 no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                href="/"
              >
                <img src="/logo-crop.png" alt="" />
              </a>
              <div className="flex h-10 justify-end content-center">
                <a className="flex -my-2 px-6 font-bold text-gray-600 no-underline items-center text-center h-10 md:h-auto md:p-4">
                  <UserIcon className="h-5 w-5 mx-2" /> {user.name}
                </a>
                <button
                  className="flex py-2 px-2 font-semibold rounded shadow-md text-white bg-red-500 hover:bg-red-700"
                  onClick={logout}
                >
                  <LogoutIcon className="h-6 w-6 mx-2" /> Déconnexion
                </button>
              </div>
        </div>
        <InfoHeader />
        </> ) : null}
      </div>
    </>
  );
};
