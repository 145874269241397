import { useState, useContext } from "react";
import { LockClosedIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom";
import { motion } from "framer-motion"
import { useForm } from "react-hook-form";

import { AuthContext } from "../contexts/Auth/AuthState";
import apiClient from "../services/api";
import { ReturnButton } from "../components/ReturnButton";

export const Login = (props) => {
  const { login, redirectToMainPage } = useContext(AuthContext);
  redirectToMainPage();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (credentials) => {
    setLoading(true);
    apiClient.get("/sanctum/csrf-cookie").then(() => {
      apiClient.post("/api/login", credentials).then((response) => {
        if (response.data.status === 200) {
          login(response.data)
          props.history.push("/fournisseur");
        } else {
          setError(response.data.message);
          setLoading(false);
        }
      }).catch((error) => {
        setError(error.response?.data.errors);
        setLoading(false);
      });
    });
  }

  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="max-w-sm mx-auto bg-white shadow-lg px-8 pt-6 pb-8 mb-4 flex flex-col">
        <img className="mx-auto justify-center" alt="logo" src="logo.png" />
        <hr className="my-5"></hr>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="block text-grey-darker text-sm mb-2" htmlFor="CodeICE">
              Votre code ICE
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3" {...register("code_ice", { required: true })} placeholder="Code ICE" />
            {error ? <p className="text-red-500 font-bold text-xs italic"> {error.code_ice || ((typeof error === 'string') ? error : null)} </p> : null}
            {errors.code_ice && <p className="text-red-500 font-bold text-xs italic">Code ICE est obligatoire.</p>}
          </div>
          <div className="mb-6">
            <label className="block text-grey-darker text-smmb-2" htmlFor="password">
              Mot de passe
            </label>
            <input className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" type="password" placeholder="*******"
              {...register("password", { required: true })} />
            {error ? <p className="text-red-500 font-bold text-xs italic"> {error.password || ((typeof error === 'string') ? error : null)} </p> : null}
            {errors.password && <p className="text-red-500 font-bold text-xs italic">Mot de passe est obligatoire.</p>}
          </div>
          <div className="flex  items-center text-center">
            <button className="w-full center items-center flex py-2 px-12 w-48 font-semibold rounded shadow text-white bg-green-600 hover:bg-green-700" type="submit">
              {loading ? <img src="three-dots.svg" alt="loading" className="h-6 w-6 mx-auto" /> : <> <LockClosedIcon className="h-6 w-6 mx-8" /> Se Connecter </>}
            </button>
          </div>
          <div className="flex items-center text-center">
            <Link to="/forgot" className="py-3 inline-block align-baseline font-bold text-sm text-blue-600 hover:text-blue-900 hover:underline" > Mot de passe oubliée? </Link>
          </div>
        </form>

        <ReturnButton />
      </div>
    </motion.div>
  );
};
