import React, { useState } from 'react'
import { DocumentTextIcon, HashtagIcon, UploadIcon, DocumentAddIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { motion } from 'framer-motion';

import apiClient from '../../services/api';
import { ReturnButton } from "../ReturnButton";

export const AddInvoice = (props) => {

    const [number, setNumber] = useState(null)
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null);

    const data = new FormData()
    data.append('number', number)
    data.append('file', file)

    const submitInvoice = (e) => {
        setLoading(true)
        e.preventDefault();
        apiClient.post("/api/invoices", data).then(() => {
            props.history.push("/factures");
        }).catch((error) => {
            setError(error.response.data.errors)
            setLoading(false)
        });
    }

    return (
        <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
        >
            <div>
                <ReturnButton />
                <form className="container mx-auto px-12 my-6" onSubmit={submitInvoice}>
                    <div class="flex items-end justify-center pt-4 px-6 text-center sm:p-0">

                        <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-md transform transition-all w-full">
                            <h1 className="flex p-6 text-xl bg-gray-200"> <DocumentAddIcon className="h-8 w-8" /> Nouvelle Facture </h1>
                            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div class="sm:flex sm:items-start">
                                </div>
                                <div>
                                    <div className="mb-4">
                                        <label className="flex block text-grey-darker text-sm mb-2" htmlFor="number">
                                            <HashtagIcon className="h-5 w-5" /> Montant
                                        </label>
                                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3" id="number" name="number" type="text" placeholder="Montant"
                                            onChange={e => setNumber(e.target.value)} required={true} />
                                        {error ? <p className="text-red-500 font-bold text-xs italic"> {error.number || ((typeof error === 'string') ? error : null)} </p> : null}
                                    </div>
                                    <div className="mb-6">
                                        <label className="flex block text-grey-darker text-sm mb-2" htmlFor="file">
                                            <DocumentTextIcon className="h-5 w-5" /> Fichier Facture
                                        </label>
                                        <input className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" id="file" type="file" name="file"
                                            onChange={e => setFile(e.target.files[0])} />
                                        {error ? <p className="text-red-500 font-bold text-xs italic"> {error.file || ((typeof error === 'string') ? error : null)} </p> : null}
                                    </div>
                                </div>
                            </div>
                            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button type="button" class="flex w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                    type="submit" >
                                    {loading ? <img src="three-dots.svg" alt="loading" className="h-6 w-6 mx-auto" /> : <> <UploadIcon className="h-5 w-5 mx-1" /> Ajouter </>}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="text-center text-yellow-700 font-semibold bg-yellow-200 p-4 mx-6 lg:m-0 rounded shadow-md">
                        <ExclamationCircleIcon className="mx-auto h-5 w-5 mx-1" /> Remarques :
                        <ul>
                            <li>Le fichier doit être soumis en format PDF.</li>
                            <li>La taille du fichier ne doit pas dépasser 20Mo.</li>
                        </ul>
                    </div>
                </form>
            </div>
        </motion.div>
    )
}
