import React from 'react'

import apiClient from '../../services/api'

export const InvoiceRow = ({ invoice }) => {

    const { id_invoice, number, created_at } = invoice
    const date = new Date(created_at).toISOString().substring(0, 10)

    const getInvoiceFile = () => {
        apiClient.get("/api/invoices/" + id_invoice, { responseType: 'blob' }).then((response) => {
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        }, () => {
            console.log("Something went wrong.");
        }).catch(error => {
            console.log(error.message);
        });
    };

    return (
        <tr>
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">
                    <a className="text-blue-500 hover:underline" href={"#F" + date }
                        onClick={() => getInvoiceFile()}> {"F" + date + ".pdf"} </a>
                </p>
            </td>
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="flex text-gray-900 whitespace-no-wrap"> {number + " DH"} </p>
            </td>
            <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                    <span
                        aria-hidden
                        className="absolute inset-0 bg-green-200 opacity-50 rounded-full"
                    ></span>
                    <span className="relative"> {date || "N/A"} </span>
                </span>
            </td>
        </tr>
    )
}
