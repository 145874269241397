import React from "react";
import { Route, Switch } from "react-router-dom";
import { LandingPage } from "../pages/LandingPage";
import { Fournisseur } from "../pages/Fournisseur";
import { Forgot } from "../pages/Password/Forgot";
import { Reset } from "../pages/Password/Reset";
import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import { ProtectedRoute } from "./ProtectedRoute";
import { Page404 } from "../pages/Page404";
import { InvoiceList } from "../components/Fournisseur/InvoiceList"
import { AddInvoice } from "../components/Fournisseur/AddInvoice";

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/forgot" component={Forgot} />
      <Route exact path="/reset/:token" component={Reset} />
      <Route exact path="/register" component={Register} />
      <ProtectedRoute exact path="/fournisseur" component={Fournisseur} />
      <ProtectedRoute exact path="/factures" component={InvoiceList} />
      <ProtectedRoute exact path="/ajouter" component={AddInvoice} />
      <Route path="*" component={Page404} />
    </Switch>
  );
};
