import React, { createContext, useReducer, useEffect } from "react";

import { AuthReducer } from "./AuthReducer";
import apiClient from "../../services/api";
import { LOGIN, LOGOUT } from "../Types";
import { useHistory } from "react-router-dom";

const initialState = {
  isLoggedIn: sessionStorage.getItem("loggedIn") === "true" || false,
  user: sessionStorage.getItem("loggedInUser")
    ? JSON.parse(sessionStorage.getItem("loggedInUser"))
    : null,
};

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const history = useHistory();
  const FOURNISSEUR_MAIN_PAGE = "/fournisseur";

  const login = (data) => {
    sessionStorage.setItem("loggedIn", true);
    sessionStorage.setItem("loggedInUser", JSON.stringify(data.data));
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: data.data,
      },
    });
  };

  const logout = () => {
    apiClient.post("/api/logout").then(
      () => {
        sessionStorage.setItem("loggedIn", false);
        sessionStorage.setItem("loggedInUser", null);
        history.push("/");
        dispatch({ type: LOGOUT });
      }, () => {
        console.log("Something went wrong.");
        sessionStorage.setItem("loggedIn", false);
        sessionStorage.setItem("loggedInUser", null);
        dispatch({ type: LOGOUT });
      }
    ).catch(() => {
      console.log("Something went wrong.");
      sessionStorage.setItem("loggedIn", false);
      sessionStorage.setItem("loggedInUser", null);
      dispatch({ type: LOGOUT });
      history.push("/");
    });
  };

  const me = () => {
    apiClient.get("/api/user").then(
      (response) => {
        sessionStorage.setItem("loggedIn", true);
        sessionStorage.setItem("loggedInUser", response.data);
        dispatch({
          type: LOGIN, payload: {
            isLoggedIn: true,
            user: response.data
          }
        });
      }, () => {
        console.log("Something went wrong.");
      }
    );
  };

  const checkAuth = () => {
    const { isLoggedIn, user } = state;
    return (isLoggedIn && user) ? true : false;
  };

  const redirectToMainPage = () => {
    if (checkAuth()) history.push(FOURNISSEUR_MAIN_PAGE);
  }

  const logoutAndReload = () => {
    sessionStorage.setItem("loggedIn", false);
    sessionStorage.setItem("loggedInUser", null);
    window.location.reload();
  }

  apiClient.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      try {
        switch (error.response.status) {
          case 401: // Not logged in
            logoutAndReload();
            break;
          case 419: // Session expired
            logoutAndReload();
            break;
          case 503: // Down for maintenance
            window.location.reload();
            break;
          case 500:
            break;
          default:
            return Promise.reject(error);
        }
      } catch {
        console.log(error);
      }
    }
  );

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        redirectToMainPage,
        checkAuth,
        login,
        logout,
        me
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
