import { useState, useContext } from "react";
import { PaperAirplaneIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";

import { AuthContext } from "../../contexts/Auth/AuthState";
import apiClient from "../../services/api";
import { ReturnButton } from "../../components/ReturnButton";

export const Forgot = () => {
  useContext(AuthContext).redirectToMainPage();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (email) => {
    setLoading(true);
    apiClient.get("/sanctum/csrf-cookie").then(() => {
      apiClient.post("/api/forgot", email).then((response) => {
        if (response.data.status === 200) {
          setError(response.data.message);
          setLoading(false);
        } else {
          setError(response.data.message);
          setLoading(false);
        }
      }).catch((error) => {
        setError(error.response?.data.errors);
        setLoading(false);
      });
    });
  }

  return (
    <motion.div
      exit={{ opacity: 0 }}
      initial={{ opacity: 0, y: -100 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <div className="max-w-sm mx-auto bg-white shadow-lg px-8 pt-6 pb-8 mb-4 flex flex-col">
        <img className="mx-auto justify-center" alt="logo" src="logo.png" />
        <hr className="my-5"></hr>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label className="block text-grey-darker text-sm mb-2" htmlFor="Email">
              Votre adresse email
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3" placeholder="Email"
              {...register("email", { required: true })} />
            {error ? <p className="text-red-500 font-bold text-xs italic"> {error.email || ((typeof error === 'string') ? error : null)} </p> : null}
            {errors.email && <p className="text-red-500 font-bold text-xs italic">Email est obligatoire.</p>}
          </div>
          <div className="flex items-center justify-between">
            <button className="w-full flex py-2 px-12 w-48 font-semibold rounded shadow text-white bg-green-600 hover:bg-green-700" type="submit">
              {loading ? <img src="three-dots.svg" alt="loading" className="h-6 w-6 mx-auto" /> : <> <PaperAirplaneIcon className="h-6 w-6 mx-8" /> Envoyer </>}
            </button>
          </div>
        </form>

        <ReturnButton />
      </div>
    </motion.div>
  );
};
