import { React, useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from "../contexts/Auth/AuthState";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { checkAuth } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        return checkAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: {
                from: props.location,
              },
            }}
          />
        );
      }}
    />
  );
};
