import React from 'react'
import { motion } from 'framer-motion'

export const Page404 = () => {
    return (
        <motion.div
            exit={{ opacity: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
        >
            <div className="container mx-auto text-center font-bold">
                404 NOT FOUND.
            </div>
        </motion.div>
    )
}
