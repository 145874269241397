import { UserCircleIcon } from '@heroicons/react/solid';
import { React, useContext } from 'react'
import { AuthContext } from '../../contexts/Auth/AuthState';

export const InfoHeader = () => {

    const { user: { name, code_ice, email } } = useContext(AuthContext);

    return (
        <div className="w-full container mx-auto my-6">
            <div className="card transition duration-300 ease-in-out hover:shadow-sm flex flex-col border mx-8 shadow bg-gray-50">
                <h1 className="flex font-mono text-gray-900 text-lg leading-tight border-b p-3 my-0">
                    <UserCircleIcon className="h-6 w-6 mx-2" /> { name } <small className="mx-2"> { code_ice } - { email } </small>
                </h1>
            </div>
        </div>
    );
}