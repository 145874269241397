import React from 'react'
import { Link } from 'react-router-dom'
import { HomeIcon } from '@heroicons/react/solid'

export const ReturnButton = () => {
    return (
        <div className="flex items-center text-center">
            <Link to="/" className="w-full pt-4 inline-block align-baseline font-bold text-sm text-blue-600 hover:text-blue-900 hover:underline"> <HomeIcon className="w-full  h-6 w-6" /> Retour </Link>
        </div>
    )
}
